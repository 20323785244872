import { Layout, Row, Image, Divider, Switch, Tour, TourProps } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import logo from "../../assets/images/logo.svg";
import stars from "../../assets/images/stars.svg";
import darkModeIcon from "../../assets/images/darkmode.svg";
import lightModeIcon from "../../assets/images/lightmode.svg";
import Button from "../Button";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/store";
import LogOutModal from "../../modals/LogOutModal";
import { useAuth0 } from "@auth0/auth0-react";
import "./styles.scss";
import {
  getUserPreferences,
  updateUserPreferences,
} from "../../api/services/UserPreferences";
import { sendGtmEvent } from "../../gtm";
import SessionOverModal from "../../modals/SessionOverModal";
import NoPermissionModal from "../../modals/NoPermissionModal";
const { Header } = Layout;

const TopHeader = () => {
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);
  const [isModalLogOutOpen, setIsModalLogOutOpen] = useState(false);
  const [showWhatsNew, setShowWhatsNew] = useState<boolean>(false);
  const { user, logout } = useAuth0();
  const switchRef = useRef(null);
  const showLogOutModal = useSelector((state: AppState) => state.showLogOutModal);
  const permissionModalData = useSelector((state: AppState) => state.permissionModalData);

  const dispatch = useDispatch();
  useEffect(() => {
    getData();
  }, [user]);

  const getData = async () => {
    if (user) {
      let response = await getUserPreferences(user, "dark_light_mode");
      if (
        (response.length > 0 && !response[0].agree) ||
        response.length === 0
      ) {
        setShowWhatsNew(true);
      } else {
        setShowWhatsNew(false);
      }
    }
  };

  const steps: TourProps["steps"] = [
    {
      title: "",
      description: "",
      cover: (
        <div style={{ background: "" }}>
          <Row>
            <Image src={stars} style={{ height: "35px" }} preview={false} />
            <div
              style={{
                marginLeft: "16px",
                fontSize: "32px",
                fontWeight: "700",
              }}
            >
              See what's new
            </div>
          </Row>
          <Row>
            <div
              style={{
                fontSize: "18px",
                // fontWeight: "700",
              }}
            >
              Click to explore the new dark mode feature we’ve added!
            </div>
          </Row>
        </div>
      ),
      target: () => switchRef.current,
      nextButtonProps: {
        style: {},
        children: "Got it",
        onClick: () => onTourClose(),
      },
      onClose: () => onTourClose(),
    },
  ];

  const onTourClose = async () => {
    if (user) {
      setShowWhatsNew(false);
      await updateUserPreferences(user, "dark_light_mode");
      await getData();
    }
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  return (
    <>
      <Header
        className="header"
        style={{
          backgroundColor: isDarkMode ? "#2D2C2C" : "#F9F9F9",
        }}
      >
        <Row>
          <Image src={logo} style={{ height: "65%" }} preview={false} />
          <h2
            style={{
              fontWeight: "bold",
              marginLeft: 16,
              color: isDarkMode ? "inherit" : "#262626",
            }}
          >
            Carambola
          </h2>
          <Row
            style={{
              flexGrow: 1,
              display: "flex",
            }}
          ></Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "auto",
              marginBottom: "auto",
              flexDirection: "row",
              gap: 20,
              alignSelf: "end",
            }}
          >
            <Switch
              ref={switchRef}
              style={{
                background: isDarkMode ? "#D9D9D9" : "#262626",
                width: 60,
                marginBottom: "auto",
                marginTop: "auto",
              }}
              checked={isDarkMode}
              onChange={() => {
                dispatch({ type: "SET_THEME", payload: !isDarkMode });
                sendGtmEvent("Dark mode", user);
              }}
              unCheckedChildren={
                <Image
                  src={darkModeIcon}
                  style={{
                    width: 12,
                  }}
                />
              }
              checkedChildren={
                <Image src={lightModeIcon} style={{ width: 12 }} />
              }
            />
            <Divider
              type="vertical"
              style={{
                background: isDarkMode ? "white" : "#262626",
                width: 1.5,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            />

            <Button
              icon={<PoweroffOutlined />}
              onClick={() => setIsModalLogOutOpen(true)}
              title="LOG OUT"
              ghost={false}
              style={{ border: 0, boxShadow: "none", backgroundColor: "transparent" }}
            ></Button>
          </Row>
        </Row>
        {showWhatsNew && <Tour open={true} onClose={() => {}} steps={steps} />}
      </Header>
      <LogOutModal
        onClose={() => setIsModalLogOutOpen(false)}
        isModalOpen={isModalLogOutOpen}
        onOk={() => {
          localStorage.setItem("domain", "{}");
          logoutWithRedirect();
        }}
      />
      {showLogOutModal && <SessionOverModal />}
      {permissionModalData.show && <NoPermissionModal />}
    </>
  );
};
export default TopHeader;
