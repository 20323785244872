import React, { useEffect, useState } from "react";
import {
  Row,
  Switch,
  Table,
  Tag,
  Tooltip,
  notification,
  Popconfirm,
  InputNumber,
} from "antd";
import PureCard from "../../components/PureCard/PureCard";
import "./styles.scss";
import PublisherSelect from "../DomainSelection/PublisherSelect";
import Select from "../../components/Select/Select";
import { PlusCircleOutlined, SettingOutlined } from "@ant-design/icons";
import DomainSelect from "../DomainSelection/DomainSelect";
import { COLORS } from "../../utils/colors.js";
import { getLayouts } from "../../api/services/Unit";
import Button from "../../components/Button";
import AdvertisersModal from "../../modals/AdvertisersModal";
import {
  deleteTag,
  duplicateTag,
  getTags,
  insertTag,
  setContentConfig,
  updateTag,
} from "../../api/services/Advertisers";
import { NotificationType } from "../../utils/notifictions";
import { EditIcon } from "../../assets/images/edit";
import { DuplicateIcon } from "../../assets/images/duplicate";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { DeleteIcon } from "../../assets/images/delete";
import { adTypes, AdType, Advertiser } from "../../utils/types";
import AdContentSetupModal from "../../modals/AdContentSetupModal";
import EditAdvertisersModal from "../../modals/EditAdvertisersModal";
import { IDS } from "../../utils/elementsIds";

const tags = ["GAM", "TAM", "PREBID"];

const Advertisers = () => {
  const [data, setData] = useState<any[]>([]);
  const [filters, setFilters] = useState<any>(undefined);
  const [layoutsList, setLayoutsList] = useState<number[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);
  const [showNewModal, setShowNewModal] = useState<boolean>(false);
  const [showContentSpotModal, setShowContentSpotModal] =
    useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<Advertiser>(
    {} as Advertiser
  );
  const [api, contextHolder] = notification.useNotification();
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  useEffect(() => {
    getTagsData();
    getLayoutsData();
  }, []);

  const columns = [
    {
      title: "Id",
      editable: false,
      dataIndex: "id",
      width: "5%",
    },
    {
      title: "Publisher Name",
      editable: false,
      dataIndex: "publisher",
    },
    {
      title: "Domain Name",
      editable: false,
      dataIndex: "domain",
    },
    {
      title: "WID",
      editable: false,
      dataIndex: "wid",
      width: "5%",
    },
    {
      title: "Ad Type",
      editable: false,
      dataIndex: "adType",
      render: (adType: AdType, record: any) => {
        return (
          <Tooltip
            placement="topLeft"
            title={
              " location: " +
              record.location +
              " adTimer: " +
              record.adTimer +
              " refresh: " +
              record.refresh +
              " skip: " +
              (record.skip ? "Yes" : "No") +
              " timer: " +
              record.timer
            }
            overlayStyle={{ maxWidth: "400px" }}
          >
            {adTypes[adType]}
          </Tooltip>
        );
      },
    },
    {
      title: "Tag Type",
      editable: true,
      dataIndex: "tagType",
      render: (type: string) => (
        <span>
          <Tag
            color={
              type === "GAM"
                ? COLORS.labels.pink
                : type === "TAM"
                ? COLORS.labels.yellow
                : COLORS.labels.border_primary
            }
          >
            {type}
          </Tag>
        </span>
      ),
    },
    {
      title: "Platform",
      editable: false,
      dataIndex: "platform",
      width: "10%",
      render: (type: string) => (
        <span className="firstLetterUppercase">
            { type ? type : "all" }
        </span>
      )
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Placement",
      dataIndex: "placement",
    },
    {
      title: "Enabled / Disabled",
      editable: false,
      dataIndex: "active",
      render: (_: any, record: Advertiser) => (
        <Row style={{ justifyContent: "center" }}>
          <Switch
            size="small"
            onChange={(value) => {
              onFinishEdit({ ...record, active: value });
            }}
            defaultChecked={record.active}
          />
        </Row>
      ),
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: "15%",
      render: (_: any, record: Advertiser) => (
        <Row
          style={{
            justifyContent: "space-evenly",
            width: "100%",
            alignSelf: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Button
            permission="update:providers"
            type="default"
            onClick={() => {
              setSelectedRecord(record);
              setShowEditModal(true);
            }}
            icon={
              <Tooltip
                placement="topLeft"
                title={"Edit"}
                overlayStyle={{ maxWidth: "400px" }}
              >
                <span className="icon">
                  <EditIcon
                    color={isDarkMode ? COLORS.darkMode.main : "#262626"}
                  />
                </span>
              </Tooltip>
            }
          ></Button>
          <Button
            permission="update:providers"
            type="default"
            onClick={() => {
              setSelectedRecord(record);
              setShowDuplicateModal(true);
            }}
            icon={
              <Tooltip
                placement="topLeft"
                title={"Duplicate"}
                overlayStyle={{ maxWidth: "400px" }}
              >
                <span className="icon">
                  <DuplicateIcon
                    color={isDarkMode ? COLORS.darkMode.main : "#262626"}
                  />
                </span>
              </Tooltip>
            }
          ></Button>

          <Popconfirm
            title="Delete"
            style={{ background: "red" }}
            description="Are you sure you want to delete the tag?"
            placement="topLeft"
            onConfirm={async () => {
              await deleteTag(record);
              openNotificationWithIcon("success", "Tag deleted successfully");
              getTagsData();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              permission="delete:advertisers"
              type="default"
              onClick={() => {}}
              icon={
                <Tooltip
                  placement="topLeft"
                  title={"Delete"}
                  overlayStyle={{ maxWidth: "400px" }}
                >
                  <span className="icon">
                    <DeleteIcon
                      color={isDarkMode ? COLORS.darkMode.main : "#262626"}
                    />
                  </span>
                </Tooltip>
              }
            ></Button>{" "}
          </Popconfirm>
        </Row>
      ),
    },
  ];

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  const getTagsData = async () => {
    let response = await getTags();
    let originData: Advertiser[] = [];
    for (let i = 0; i < response.length; i++) {
      originData.push({
        key: response[i].id,
        id: response[i].id,
        tagType: response[i].tagType,
        tagScript: response[i].tagScript,
        tagBackfill: response[i].tagBackfill,
        size: response[i].size,
        placement: response[i].placement,
        active: response[i].active,
        domain: response[i].name,
        publisher: response[i].pub_name,
        wid: response[i].wid,
        pub_id: response[i].pub_id,
        pub_key: response[i].public_key,
        domain_id: response[i].domain_id,
        adType: response[i].adType,
        location: response[i].location,
        adTimer: response[i].adTimer,
        refresh: response[i].refresh,
        skip: response[i].skip,
        providers: response[i].providers,
        timeout: response[i].timeout,
        bidfloor: response[i].bidfloor,
        platform: response[i].platform
      });
    }
    setData(originData);
    setLoading(false);
  };
  const getLayoutsData = async () => {
    let layouts = await getLayouts();
    setLayoutsList(layouts);
  };

  const close = () => {
    setShowNewModal(false);
    setShowEditModal(false);
    setShowDuplicateModal(false);
    setShowContentSpotModal(false);
  };

  const onFinishAddNew = async (tag: any) => {
    close();
    setLoading(true);
    tag.platform = (tag?.platform) ? tag.platform : null;
    let response = await insertTag(tag);
    if (response.status && response.status === "exsist") {
      if (response.result.active) {
        openNotificationWithIcon("warning", "You've already created the tag");
        setLoading(false);
      } else
        openNotificationWithIcon(
          "warning",
          "You've already created the tag, just enable it"
        );
      setLoading(false);
    } else {
      close();
      openNotificationWithIcon("success", "Tag added successfully");
      getTagsData();
    }
  };

  const onFinishDuplicate = async (tag: any) => {
    close();
    setLoading(true);
    tag.platform = (tag?.platform) ? tag.platform : null;
    let response = await duplicateTag(tag);
    if (response.status && response.status === "exsist") {
      if (response.result.active) {
        openNotificationWithIcon("warning", "You've already created the tag");
        setLoading(false);
      } else
        openNotificationWithIcon(
          "warning",
          "You've already created the tag, just enable it"
        );

      setLoading(false);
    } else {
      openNotificationWithIcon("success", "Tag Duplicated successfully");
      getTagsData();
    }
  };

  const onFinishSetContentConfig = async (tag: any) => {
    close();
    await setContentConfig(tag);
    close();
    openNotificationWithIcon("success", "Setup updated successfully");
    getTagsData();
  };

  const onFinishEdit = async (tag: any) => {
    close();
    setLoading(true);
    tag.platform = (tag?.platform) ? tag.platform : null;
    let response = await updateTag({ ...tag });
    if (response.status && response.status === "exsist") {
      if (response.result.active) {
        openNotificationWithIcon("warning", "You've already created the tag");
        setLoading(false);
      } else
        openNotificationWithIcon(
          "warning",
          "You've already created the tag, just enable it"
        );

      setLoading(false);
    } else {
      openNotificationWithIcon("success", "Tag updated successfully");
      getTagsData();
    }
  };

  return (
    <PureCard>
      <div className="advertisers">
        <Row style={{ justifyContent: "end", gap: "18px" }}>
          <Button
            title="Content setup"
            onClick={() => {
              setShowContentSpotModal(true);
            }}
            type="primary"
            size="large"
            icon={<SettingOutlined />}
          />

          <Button
            title="Add New Tag"
            onClick={() => {
              setShowNewModal(true);
            }}
            type="primary"
            size="large"
            icon={<PlusCircleOutlined />}
          />
        </Row>

        <Row
          style={{
            justifyContent: "space-between",
            marginBottom: "40px",
            marginTop: "30px",
          }}
        >
          <div style={{ width: "24%" }}>
            <PublisherSelect
              id={IDS.ADVERTISERS.PUBLISHER_SELECT}
              mode="new"
              allowClear
              onSelect={(pub_id, pub_key) => {
                if (pub_id) {
                  setFilters({ ...filters, pub_id });
                } else {
                  let obj = filters;
                  delete obj.pub_id;
                  setFilters({ ...filters });
                }
              }}
            />
          </div>

          <div style={{ width: "24%" }}>
            <DomainSelect
              id={IDS.ADVERTISERS.DOMAIN_SELECT}
              allowClear
              onSelect={(value) => {
                setFilters({ ...filters, domain_id: value });
              }}
            />
          </div>

          <Select
            allowClear
            id={IDS.ADVERTISERS.TAGTYPE_SELECT}
            placeholder="Select Tag Type"
            options={tags.map((tag: any) => {
              return { value: tag, label: tag };
            })}
            style={{ width: "24%" }}
            onSelect={(value) => {
              setFilters({ ...filters, tagType: value });
            }}
            onClear={() => setFilters({ ...filters, tagType: "" })}
          />

          <InputNumber
            id={IDS.ADVERTISERS.WID_INPUT}
            placeholder="Select WID"
            size="large"
            style={{ width: "12%" }}
            type="number"
            onChange={(value) => {
              if (!value && value != 0) {
                setFilters({ ...filters, wid: "" });
              } else setFilters({ ...filters, wid: value });
            }}
          />

          <InputNumber
            id={IDS.ADVERTISERS.ID_INPUT}
            placeholder="Select ID"
            size="large"
            style={{ width: "12%" }}
            type="number"
            onChange={(value) => {
              if (!value && value != 0) {
                setFilters({ ...filters, id: "" });
              } else setFilters({ ...filters, id: value });
            }}
          />
        </Row>
        <Table
          loading={loading}
          bordered
          dataSource={data.filter((record) => {
            for (const key in filters) {
              if (filters[key] !== "" && record[key] !== filters[key]) {
                return false;
              }
            }
            return true;
          })}
          columns={columns}
        />
        {showEditModal && (
          <EditAdvertisersModal
            onFinish={onFinishEdit}
            mode={"edit"}
            layoutsList={layoutsList}
            close={close}
            data={selectedRecord}
            tags={tags}
          />
        )}

        {showDuplicateModal && (
          <EditAdvertisersModal
            onFinish={onFinishDuplicate}
            mode={"duplicate"}
            layoutsList={layoutsList}
            close={close}
            data={selectedRecord}
            tags={tags}
          />
        )}

        {showNewModal && (
          <AdvertisersModal
            onFinish={onFinishAddNew}
            layoutsList={layoutsList}
            close={() => setShowNewModal(false)}
            tags={tags}
          />
        )}

        {showContentSpotModal && (
          <AdContentSetupModal
            onFinish={onFinishSetContentConfig}
            close={() => setShowContentSpotModal(false)}
          />
        )}

        {contextHolder}
      </div>
    </PureCard>
  );
};

export default Advertisers;
