import React, { useEffect, useState } from "react";
import { Image, Skeleton } from "antd";
import grabIcon from "../../assets/images/grab.svg";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, theme } from "antd";

import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableStateSnapshot,
  DropResult,
  DroppableProvided
} from "@hello-pangea/dnd";
import { Col, Row } from "antd";
import IntuitiveCard from "../../components/Card/IntuitiveCard";
import { getCardValue } from "../../utils/utils";
import { deleteMetric, insertMetric } from "../../api/services/Dashboard";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { COLORS } from "../../utils/colors";

interface MetricsProps {
  fields: any[];
  platformValue: string;
  isLoading: boolean;
  metricsSelection: number[];
  pub_id: number;
  setIsLoading: (flag: boolean) => void;
}

const Metrics = (props: MetricsProps) => {
  const {
    platformValue,
    fields,
    isLoading,
    metricsSelection,
    pub_id
  } = props;

  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [itemsToSelect, setItemsToSelect] = useState<any[]>([]);
  const { token } = theme.useToken();
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const reorder = (
    list: any[],
    startIndex: number,
    endIndex: number
  ): any[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  useEffect(() => {
    if (fields) {
      let itemsToSelectTmp = fields.filter(
        (item) => !metricsSelection.includes(item.keyForSelection)
      );
      let selectedTmp = fields.filter((item) =>
        metricsSelection.includes(item.keyForSelection)
      );
      setItemsToSelect(itemsToSelectTmp);
      setSelected(selectedTmp);
    }
  }, [fields, isLoading, metricsSelection]);

  const grid = 8;

  const move = (
    source: any[],
    destination: any[],
    droppableSource: any,
    droppableDestination: any
  ): any => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any): any => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    ...draggableStyle,
    background: isDarkMode ? COLORS.darkMode.background : "white",
  });

  const getListStyle = (isDraggingOver: boolean): any => ({
    background: isDraggingOver
      ? isDarkMode
        ? COLORS.darkMode.main
        : "#e8f2fe"
      : isDarkMode
      ? COLORS.darkMode.background
      : "white",
    padding: grid * 2,
    height: "100%",
    width: "100%",
    overflow: "scroll",
  });
  const onDragEnd = async (result: DropResult): Promise<void> => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const list =
        source.droppableId === "droppableItems" ? itemsToSelect : selected;
      const reorderedItems = reorder(list, source.index, destination.index);

      source.droppableId === "droppableItems"
        ? setItemsToSelect(reorderedItems)
        : setSelected(reorderedItems);
    } else {
      let data = { pub_id, metricKey: result.draggableId };
      if (source.droppableId === "droppableItems") {
        insertMetric(data);
      } else {
        deleteMetric(data);
      }

      const res = move(
        source.droppableId === "droppableItems" ? itemsToSelect : selected,
        destination.droppableId === "droppableItems" ? itemsToSelect : selected,
        source,
        destination
      );
      setItemsToSelect(res.droppableItems);
      setSelected(res.droppableSelected);
    }
  };

  const getLoadingBoard = () => {
    return (
      <Row style={{ margin: "16px" }}>
        {[0, 1, 2, 3, 4, 5].map((item, index) => (
          <Skeleton
            active
            avatar
            paragraph={{ rows: 2 }}
            key={index}
            style={{ marginTop: "16px", width: "90%" }}
          />
        ))}
      </Row>
    );
  };

  const collapseItems = [
    {
      key: "1",
      label: isCollapse ? "Display Metrics" : "Hide Metrics",
      children: (
        <Col
          className="metrics-items"
          style={{
            width: "100%",
            background: isDarkMode ? COLORS.darkMode.background : "white",
          }}
        >
          <Droppable droppableId="droppableItems">
            {(
              provided: DroppableProvided,
              snapshot: DroppableStateSnapshot
            ) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {!isLoading &&
                  itemsToSelect.map((item, index) => {
                    return (
                      item && (
                        <Draggable
                          key={item.Label}
                          draggableId={`${item.keyForSelection}`}
                          index={index}
                        >
                          {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => (
                            <Row
                              className={
                                isDarkMode ? "card-dark" : "card-light"
                              }
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Col span={3} style={{ background: "" }}>
                                {" "}
                                <Image
                                  src={grabIcon}
                                  preview={false}
                                  style={{ width: 20 }}
                                />
                              </Col>

                              <Col style={{ 
                                  background: "", 
                                  textOverflow: "ellipsis", 
                                  overflow: "hidden",
                                  width: "85%", 
                                  whiteSpace: "nowrap"
                                }} title={item.Label}>{item.Label}</Col>
                            </Row>
                          )}
                        </Draggable>
                      )
                    );
                  })}
                {isLoading && getLoadingBoard()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Col>
      ),
      style: panelStyle,
    },
  ];

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Row className="metrics" gutter={0} style={{ width: "100%" }}>
        <Col
          className="metrics-selected"
          style={{
            width: "79%"
          }}
        >
          {!isLoading && (
            <Droppable droppableId="droppableSelected">
              {(
                provided: DroppableProvided,
                snapshot: DroppableStateSnapshot
              ) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <Row gutter={16}>
                    {selected.map((filed: any, index: number) => {
                      return (
                        filed && (
                          <Draggable
                            key={filed.Label}
                            draggableId={`${filed.keyForSelection}`}
                            index={index}
                          >
                            {(
                              provided: DraggableProvided,
                              snapshot: DraggableStateSnapshot
                            ) => (
                              <Col
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                key={index}
                                span={8}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <IntuitiveCard
                                  title={filed.Label}
                                  description={getCardValue(filed, platformValue)}
                                  desktop={filed.Desktop}
                                  mobile={filed.Mobile}
                                />
                              </Col>
                            )}
                          </Draggable>
                        )
                      );
                    })}
                  </Row>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </Col>

        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          style={{
            background: isDarkMode ? COLORS.darkMode.background : "white",
            width: "20%",
            overflow: "scroll",
            height: "100%",
          }}
          items={collapseItems}
          onChange={(v) => setIsCollapse(v.length > 0 ? false : true)}
        />
      </Row>
    </DragDropContext>
  );
};

export default Metrics;
