import Select from "../../components/Select/Select";
import { useEffect, useState } from "react";

import { Form, Row, Spin } from "antd";

import {
  createDefaultWidget,
  isActiveOptions,
  widgetContentType,
} from "../../utils/utils";

const Widget = (props: any) => {
  const {
    pub_id,
    data,
    onLoad,
    onUpdateWidget,
    mode,
    allWidgets,
    allLayoutTypes,
    allItemTypes,
  } = props;

  const PLATFORMS = ["Desktop", "Mobile", "Tablet"];
  const [loading, setLoading] = useState<boolean>(false);

  const [widget, setWidget] = useState<any>(
    data ? data : createDefaultWidget(pub_id)
  );

  const WIDGET_PROPERTIES_MAP = {
    Desktop: "is_in_content",
    Mobile: "is_mobile",
    Tablet: "is_tablet",
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getData = async () => {
    if (mode === "edit") {
      setWidget({
        ...widget,
        item_type: data.item_type !== "" ? data.item_type : "",
        layout_type: data.layout_type !== "" ? data.layout_type : "",
      });
    } else {
      setWidget({
        ...widget,
        item_type: "",
        layout_type: "",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (mode === "new" || typeof data.id === "string") {
        setLoading(true);
      }
      await getData();
      onLoad();
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (onUpdateWidget) {
      onUpdateWidget(widget);
    }
  }, [widget]);

  return (
    <div>
      <Spin spinning={loading}>
        <Row justify="space-between">
          <div style={{ width: "8%" }}>
            <Form.Item label={"WID"} name={`wid_${widget.id}`}>
              <Select
                showSearch
                placeholder="Select WID"
                optionFilterProp="children"
                filterOption={filterOption}
                options={allWidgets.map((wid: any) => {
                  return { value: wid, label: wid.toString() };
                })}
                {...(widget && {
                  defaultValue: widget.widget_id,
                })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    widget_id: value,
                  });
                }}
              />
            </Form.Item>
          </div>

          <div style={{ width: "22%" }}>
            <Form.Item
              label={"Choose Platforms"}
              name={`platforms_${widget.id}`}
              initialValue={[
                ...(widget.is_in_content ? ["Desktop"] : []),
                ...(widget.is_mobile ? ["Mobile"] : []),
                ...(widget.is_tablet ? ["Tablet"] : []),
              ]}
              rules={[
                {
                  required: true,
                  message: "Please Choose Platform",
                },
              ]}
            >
              <Select
                showSearch
                mode="multiple"
                placeholder="Select Platform"
                className="platform"
                options={PLATFORMS.map((plt: any) => {
                  return { value: plt, label: plt };
                })}
                defaultValue={[
                  ...(widget.is_in_content ? ["Desktop"] : []),
                  ...(widget.is_mobile ? ["Mobile"] : []),
                  ...(widget.is_tablet ? ["Tablet"] : []),
                ]}
                onSelect={(selectedPlatform: string) => {
                  const selected =
                    WIDGET_PROPERTIES_MAP[
                      selectedPlatform as keyof typeof WIDGET_PROPERTIES_MAP
                    ];

                  setWidget({
                    ...widget,
                    [selected]: 1,
                  });
                }}
                onDeselect={(deselectedPlatform: string) => {
                  const deselected =
                    WIDGET_PROPERTIES_MAP[
                      deselectedPlatform as keyof typeof WIDGET_PROPERTIES_MAP
                    ];

                  setWidget({
                    ...widget,
                    [deselected]: 0,
                  });
                }}
              />
            </Form.Item>
          </div>
          <div style={{ width: "17%" }}>
            <Form.Item
              name={`layoutType_${widget.id}`}
              label={"Choose Layout Type"}
              key={`layout_type_${widget.id}`}
              {...(widget.layout_type && { initialValue: widget.layout_type })}
              rules={[
                {
                  required: true,
                  message: "Please Choose Layout Type",
                },
              ]}
            >
              <Select
                allowClear
                placeholder="Select Layout"
                options={allLayoutTypes.map((layout: any) => {
                  return { value: layout, label: layout };
                })}
                {...(widget.layout_type && {
                  defaultValue: widget.layout_type,
                })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    layout_type: value,
                  });
                }}
              />
            </Form.Item>
          </div>

          <div style={{ width: "17%" }}>
            <Form.Item
              name={`itemType_${widget.id}`}
              label="Choose Item Type"
              key={`itemType_${widget.id}`}
              rules={[
                {
                  required: true,
                  message: "Please Choose Item Type",
                },
              ]}
              {...(widget.item_type && { initialValue: widget.item_type })}
            >
              <Select
                allowClear
                placeholder="Select Item Type"
                options={allItemTypes.map((item: any) => {
                  return { label: item, value: item };
                })}
                {...(widget.item_type && { defaultValue: widget.item_type })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    item_type: value,
                  });
                }}
              />
            </Form.Item>
          </div>

          <div style={{ width: "15%" }}>
            <Form.Item
              name={`contentType_${widget.id}`}
              label="Choose Content Type"
              key={`contentType_${widget.id}`}
              rules={[
                {
                  required: true,
                  message: "Please Choose Content Type",
                },
              ]}
              {...(widget.contentType && { initialValue: widget.contentType })}
            >
              <Select
                allowClear
                placeholder="Select Content Type"
                options={widgetContentType.map((item: any) => {
                  return { label: item.label, value: item.value };
                })}
                {...(widget.contentType && {
                  defaultValue: widget.contentType,
                })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    contentType: value,
                  });
                }}
              />
            </Form.Item>
          </div>

          <div style={{ width: "16%" }}>
            <Form.Item
              name={`active${widget.id}`}
              label="Choose Is Active"
              key={`active_${widget.id}`}
              rules={[
                {
                  required: true,
                  message: "Please Choose Is Active",
                },
              ]}
              {...(widget.active != null && { initialValue: widget.active })}
            >
              <Select
                allowClear
                placeholder="Select Is Active"
                options={isActiveOptions.map((item: any) => {
                  return { label: item.label, value: item.value };
                })}
                {...(widget.active != null && {
                  defaultValue: widget.active,
                })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    active: value,
                  });
                }}
              />
            </Form.Item>
          </div>
        </Row>
      </Spin>
    </div>
  );
};
export default Widget;
