import Modal from "../components/Modal/Modal";
import { NotificationType } from "../utils/notifictions";
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  notification,
} from "antd";
import PublisherSelect from "../pages/DomainSelection/PublisherSelect";
import DomainSelect from "../pages/DomainSelection/DomainSelect";
import Select from "../components/Select/Select";
import TextArea from "antd/es/input/TextArea";
import Button from "../components/Button";
import { useState } from "react";

import { Advertiser, Provider, Setup } from "../utils/types";
import { getSetups } from "../api/services/Advertisers";
import { IDS } from "../utils/elementsIds";
import { MinusOutlined, PlusCircleOutlined } from "@ant-design/icons";
import PureCard from "../components/PureCard/PureCard";
import {
  adTypeOptions,
  defaultTimer,
  getUuidv4,
  getallDomainesUrl,
  providersOptions,
  unitSizeOptions,
  validateField,
} from "../utils/utils";

interface AdvertisersModalProps {
  tags: string[];
  layoutsList: any[];
  close: () => void;
  onFinish: (tag: any) => void;
  data?: any;
}

const AdvertisersModal = (props: AdvertisersModalProps) => {
  const { close, tags, onFinish } = props;
  const [setups, setSetups] = useState<Setup>({} as Setup);
  const [stage, setStage] = useState<"details" | "loading" | "setup">(
    "details"
  );
  const [advertiser, setAdvertiser] = useState<Advertiser>({} as Advertiser);
  const [loading, setLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();

  const setSetup = async () => {
    if (stage === "details") {
      setStage("loading");

      let setupsRes = await getSetups({
        pub_id: advertiser.pub_id,
        wid: advertiser.wid,
        domain_id: advertiser.domain_id,
      });
      if (setupsRes.inContent && setupsRes.inContent.location) {
        setSetups({
          ...setupsRes,
          inContent: {
            ...setupsRes.inContent,
            location: setupsRes.inContent.location.split(",").map(Number),
          },
        });
      } else setSetups(setupsRes);

      setStage("setup");
    } else {
      setAdvertiser({
        ...advertiser,
        adType: null,
        adTimer: null,
        location: null,
        refresh: null,
        skip: false,
      });
      setStage("details");
    }
  };

  const getAdTimerValue = (adType: string) => {
    
      if (adType.includes("inContent")) {
        return setups.inContent?.adTimer || 15;
      }
      if (adType.includes("firstImpression")) {
        return setups.firstImpression?.adTimer || 15;
      } else if (adType.includes("outOfFrame")) {
        return 0;
      } else return 0;
    
  };

  const getRefreshValue = (adType: string) => {
    
      if (adType.includes("inContent")) {
        return setups.inContent?.refresh || 0;
      }
      if (adType.includes("firstImpression")) {
        return setups.firstImpression?.refresh || 0;
      } else if (adType.includes("outOfFrame")) {
        return 30;
      } else return 0;
    
  };

  const getLocationValue = (adType: string) => {
    let all = Array.from({ length: 20 }, (_, index) => index + 1).map(
      (value: number) => {
        return value;
      }
    );

    if (adType.includes("inContent")) {
      return setups.inContent?.location || all;
    } else if (adType.includes("firstImpression")) {
      return [1];
    } else if (adType.includes("outOfFrame")) {
      return [];
    } else return [];
  };

  const getSkipValue = (adType: string) => {
    if (adType.includes("inContent")) {
      return setups.inContent?.skip || false;
    } else if (adType.includes("firstImpression")) {
      return setups.firstImpression?.skip || true;
    } else if (adType.includes("outOfFrame")) {
      return setups.firstImpression?.skip || false;
    } else return false;
  };

  const getProviderUnit = (provider: Provider, index: number) => {
    let fields: string[] = [];
    let res: any[] = [];

    switch (provider.providerName) {
      case "33across":
        fields = ["siteId", "productId"];
        break;
      case "eplanning":
        fields = ["ci"];
        break;
      case "smartadserver":
        fields = ["networkId", "siteId", "pageId", "formatId"];
        break;
      case "rubicon":
        fields = ["accountId", "siteId", "zoneId"];
        break;
      case "openx":
        fields = ["delDomain", "unit"];
        break;
      case "pubmatic":
        fields = ["publisherId"];
        break;
      case "rise":
        fields = ["org"];
        break;
      case "sovrn":
        fields = ["tagid"];
        break;
      case "appnexus":
        fields = ["placementId"];
        break;
      case "nextMillennium":
        fields = ["placement_id"];
        break;
      case "minutemedia":
        fields = ["org"];
        break;
      case "onetag":
        fields = ["pubId"];
        break;        
      default:
        fields = [""];
        break;
    }

    res.push(
      <Button
        size="small"
        style={{
          marginTop: "auto",
          marginBottom: "auto",
          borderRadius: "50%",
        }}
        type="primary"
        icon={<MinusOutlined />}
        onClick={() => {
          let providers = [...advertiser.providers];
          providers.splice(index, 1);
          setAdvertiser({
            ...advertiser,
            providers,
          });
        }}
      ></Button>
    );

    res.push(
      <Form.Item
        label="Choose Provider"
        name={`provider_${provider.id}`}
        rules={[{ required: true, message: "Please select provider" }]}
      >
        <Select
          style={{ width: "230px" }}
          allowClear
          placeholder="Please select provider"
          options={getProvidersOptions()}
          onChange={(value: any) => {
            let providers = [...advertiser.providers];
            let provider = providers[index];
            provider.providerName = value;
            providers[index] = provider;

            setAdvertiser((prevState) => ({
              ...prevState,
              providers: providers,
            }));
          }}
          defaultValue={provider.providerName || undefined}
          disabledVirtual
        />
      </Form.Item>
    );
    for (let field of fields) {
      field !== "" &&
        res.push(
          <Form.Item
            label={`Type ${ field }`}
            name={`${field}_${provider.id}`}
            rules={[
              {
                validator: validateField,
                message: `Please type ${field}`,
              },
            ]}
          >
            <Input
              placeholder={`Please Type ${field}`}
              size="large"
              onChange={(e) => {
                let value = e.target.value;
                let providers = [...advertiser.providers];
                let provider = providers[index];
                provider.providerFields[field] = value;
                setAdvertiser((prevState) => ({
                  ...prevState,
                  providers: providers,
                }));
              }}
            />
          </Form.Item>
        );
    }

    return res;
  };

  const getProvidersOptions = () => {
    let selectedProviders = advertiser.providers.map(
      (provider) => provider.providerName
    );
    return providersOptions
      .filter((provider) => !selectedProviders.includes(provider.providerName))
      .map((provider) => provider.providerName)
      .map((provider: string) => {
        return { value: provider, label: provider };
      });
  };

  const isAllProvidersSelected = () => {
    if (advertiser.providers) {
      let selectedProviders = advertiser.providers.map(
        (provider) => provider.providerName
      );
      return (
        providersOptions.filter(
          (provider) => !selectedProviders.includes(provider.providerName)
        ).length === 0
      );
    } else return false;
  };

  let checkPubIdRelatedToDomain = async () => {
    let data = await getallDomainesUrl(advertiser.pub_id);
    let domains = data.map((item) => item.value);
    if (!domains.includes(advertiser.domain_id)) return false;
    return true;
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };
  return (
    <Modal
      width={"70%"}
      title={<h2>{"Add New tag"}</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
      removeCloseButton={true}
    >
      <Form
        name="basic"
        onFinish={async (tag: any) => {
          setLoading(true);
          if (await checkPubIdRelatedToDomain()) {
            onFinish(advertiser);
          } else {
            openNotificationWithIcon(
              "warning",
              "The domain is not related to the publisher"
            );
          }
          setLoading(false);
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
        layout="vertical"
        requiredMark
      >
        <Row
          style={{
            width: "100%",
            background: "",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            label="Choose Publisher"
            name="publisher"
            rules={[
              {
                required: !advertiser.pub_id,
                message: "Please select publisher",
              },
            ]}
            style={{ width: "31%" }}
          >
            <PublisherSelect
              id={IDS.NEW_TAG.PUBLISHER_SELECT}
              disabled={stage !== "details"}
              mode="new"
              onSelect={(pub_id, pub_key) => {
                setAdvertiser({
                  ...advertiser,
                  pub_id: pub_id,
                  pub_key: pub_key,
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Choose Domain"
            name="domain"
            rules={[
              {
                required: !advertiser.domain_id,
                message: "Please select domain",
              },
            ]}
            style={{ width: "31%" }}
          >
            <DomainSelect
              id={IDS.NEW_TAG.DOMAIN_SELECT}
              onSelect={(value: any) => {
                setAdvertiser({
                  ...advertiser,
                  domain_id: value,
                });
              }}
              disabled={!advertiser.pub_id || stage !== "details"}
            />
          </Form.Item>

          <Form.Item
              name="platform"
              label="Platform"
              style={{ width: "31%" }}
            >
              <Select
                id={IDS.NEW_TAG.PLATFORM_SELECT}
                placeholder="Select Platform Type"
                options={[
                  { value: "", label: "All" },
                  { value: "desktop", label: "Desktop" },
                  { value: "mobile", label: "Mobile" }
                ]}
                onChange={(value: any) => {
                    setAdvertiser({
                      ...advertiser,
                      platform: value
                    });
                }}
              />
            </Form.Item>

        </Row>

        <Row
          style={{
            width: "100%",
            background: "",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            label="Choose WID"
            name="wid"
            style={{ width: "31%" }}
            rules={[
              { required: true, message: "Please select WID" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("wid") < 0) {
                    return Promise.reject("Please select number > 0");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              id={IDS.NEW_TAG.WID_INPUT}
              disabled={stage !== "details"}
              placeholder="Please select WID"
              size="large"
              style={{ width: "100%" }}
              type="number"
              onChange={(value: any) => {
                setAdvertiser({
                  ...advertiser,
                  wid: value,
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Choose Tag Type"
            name="type"
            rules={[{ required: true, message: "Please select tag type" }]}
            style={{ width: "31%" }}
          >
            <Select
              id={IDS.NEW_TAG.TAGTYPE_SELECT}
              disabled={stage !== "details"}
              allowClear
              placeholder="Select Tag Type"
              options={tags.map((tag: any) => ({ value: tag, label: tag }))}
              onChange={(value: any) => {
                if (value === "PREBID") {
                  setAdvertiser({
                    ...advertiser,
                    tagType: value,
                  });
                } else {
                  setAdvertiser({
                    ...advertiser,
                    tagType: value,
                    providers: [],
                  });
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="Choose Size"
            name="size"
            rules={[{ required: true, message: "Please select size" }]}
            style={{ width: "31%" }}
          >
            <Select
              disabled={stage !== "details"}
              allowClear
              placeholder="Please select Size"
              options={unitSizeOptions.map((size: string) => {
                return { value: size, label: size };
              })}
              onChange={(value: any) =>
                setAdvertiser({
                  ...advertiser,
                  size: value,
                })
              }
            />
          </Form.Item>
        </Row>

        <Row
          style={{
            width: "100%",
            background: "",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            label="Type Placement"
            name="placement"
            rules={[
              {
                validator: validateField,
                message: "Please type Placement",
              },
            ]}
            style={{ width: "31%" }}
          >
            <TextArea
              disabled={stage !== "details"}
              placeholder="Please Type Placement"
              size="large"
              style={{ height: 100 }}
              onChange={(e) => {
                setAdvertiser({
                  ...advertiser,
                  placement: e.target.value,
                });
              }}
            />
          </Form.Item>

          <Form.Item
              label="Type Backfill Script"
              name="tagBackfill"
              style={{ width: "31%" }}
            >
              <TextArea
                disabled={stage !== "details"}
                placeholder="Paste your backfill script here"
                style={{
                  display: "flex",
                  height: 100,
                }}
                onChange={(e) =>
                  setAdvertiser({
                    ...advertiser,
                    tagBackfill: e.target.value,
                  })
                }
              />
          </Form.Item>

          {advertiser.tagType === "PREBID" && (
            <>
              <Form.Item
                label="Timeout"
                name="timeout"
                style={{ width: "14%" }}
                rules={[
                  { required: false, message: "Please type Timeout" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("timeout") < 0) {
                        return Promise.reject("Please type number > 0");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  id={IDS.NEW_TAG.TIMEOUT_INPUT}
                  disabled={stage !== "details"}
                  placeholder="800"
                  size="large"
                  style={{ width: "100%" }}
                  type="number"
                  onChange={(value: any) => {
                    setAdvertiser({
                      ...advertiser,
                      timeout: value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item                 
                label="Floor Price"
                name="bidfloor"
                style={{ width: "14%" }}
                rules={[
                  { required: false, message: "Please type Bid Floor" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("bidfloor") < 0) {
                        return Promise.reject("Please type number > 0");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  id={IDS.NEW_TAG.BIDFLOOR_INPUT}
                  disabled={stage !== "details"}
                  placeholder="0.05"
                  size="large"
                  style={{ width: "100%" }}
                  type="number"
                  onChange={(value: any) => {
                    setAdvertiser({
                      ...advertiser,
                      bidfloor: value,
                    });
                  }}
                />

              </Form.Item>
            </>
          )}

          {advertiser.tagType !== "PREBID" && (
            <Form.Item
              label="Type Script"
              name="tagScript"
              style={{ width: "31%" }}
              rules={[
                { validator: validateField, message: "Please type script" },
              ]}
            >
              <TextArea
                disabled={stage !== "details"}
                placeholder="Paste your script here"
                style={{
                  display: "flex",
                  height: 100,
                }}
                onChange={(e) =>
                  setAdvertiser({
                    ...advertiser,
                    tagScript: e.target.value,
                  })
                }
              />
            </Form.Item>
          )}
        </Row>

        {advertiser.tagType === "PREBID" && (
          <PureCard>
            <Row
              style={{
                overflow: "scroll",
                background: "",
                maxHeight: "250px",
              }}
            >
              {
                <Row
                  justify="end"
                  style={{
                    width: "100%",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <Button
                    disabled={isAllProvidersSelected()}
                    icon={<PlusCircleOutlined />}
                    style={{ marginBottom: "16px", alignSelf: "end" }}
                    title="Add Provider"
                    type="primary"
                    onClick={() => {
                      let providers = advertiser.providers || [];
                      providers.push({
                        providerName: null,
                        providerFields: {},
                        id: getUuidv4(),
                      });

                      setAdvertiser((prevState) => ({
                        ...prevState,
                        providers: providers,
                      }));
                    }}
                  />
                </Row>
              }
              {advertiser.providers?.map(
                (provider: Provider, index: number) => {
                  return (
                    <Row
                      style={{
                        width: "100%",
                      }}
                      key={index}
                    >
                      <Space size={"large"}>
                        {getProviderUnit(provider, index)}
                      </Space>
                    </Row>
                  );
                }
              )}
            </Row>
          </PureCard>
        )}

        <Button
          loading={stage === "loading"}
          style={{ width: "100%", marginBottom: "24px", marginTop: "24px" }}
          type="primary"
          title={stage !== "setup" ? "Set Setup" : "Back To Set Tag"}
          onClick={() => setSetup()}
          size="middle"
          disabled={
            !advertiser.pub_id ||
            !advertiser.domain_id ||
            (!advertiser.wid && advertiser.wid !== 0) ||
            !advertiser.tagType ||
            !advertiser.size ||
            !advertiser.placement ||
            (advertiser.tagType !== "PREBID" && !advertiser.tagScript)
          }
        ></Button>

        {stage === "setup" && (
          <>
            <Row justify="center">
              <Form.Item
                label="Choose Ad type"
                // name="adType"
                required
                rules={[{ required: true, message: "Please select Ad type" }]}
                style={{ width: "100%" }}
              >
                <Select
                  id={IDS.NEW_TAG.ADTYPE_SELECT}
                  allowClear
                  placeholder="Please select Ad type"
                  options={adTypeOptions.map((item: any) => {
                    return { value: item.value, label: item.label };
                  })}
                  onSelect={(value) => {
                    setAdvertiser({
                      ...advertiser,
                      adType: value,
                      location: getLocationValue(value),
                      adTimer: getAdTimerValue(value),
                      refresh: getRefreshValue(value),
                      skip: getSkipValue(value),
                    });
                  }}
                />
              </Form.Item>
            </Row>
            <Row justify="space-between" style={{ width: "100%" }}>
              {advertiser.adType && (
                <>
                  <Checkbox
                    onChange={(e) => {
                      setAdvertiser({
                        ...advertiser,
                        adTimer: e.target.checked
                          ? defaultTimer
                          : getAdTimerValue(advertiser.adType || ""),
                      });
                    }}
                  >
                    Disabled
                  </Checkbox>
                  <Form.Item
                    label="Choose Ad Timer (in seconds)"
                    style={{ width: "25%" }}
                    rules={[
                      { required: true, message: "Please select ad timer" },
                    ]}
                  >
                    <InputNumber
                      hidden={advertiser.adTimer === defaultTimer}
                      style={{ width: "100%" }}
                      value={advertiser.adTimer}
                      placeholder="Please select Ad timer"
                      size="large"
                      type="number"
                      onChange={(value: any) =>
                        setAdvertiser({
                          ...advertiser,
                          adTimer: value || 0,
                        })
                      }
                    />
                  </Form.Item>
                </>
              )}

              {advertiser.adType && (
                <>
                  <Checkbox
                    onChange={(e) => {
                      setAdvertiser({
                        ...advertiser,
                        refresh: e.target.checked ? defaultTimer : 0,
                      });
                    }}
                  >
                    Disabled
                  </Checkbox>

                  <Form.Item
                    label="Choose Refresh (in seconds)"
                    rules={[
                      {
                        required: true,
                        message: "Please select refresh",
                      },
                    ]}
                    required
                    style={{ width: "25%" }}
                  >
                    <InputNumber
                      hidden={advertiser.refresh === defaultTimer}
                      style={{ width: "100%" }}
                      value={advertiser.refresh}
                      placeholder="Please select refresh"
                      size="large"
                      type="number"
                      onChange={(value) =>
                        setAdvertiser({
                          ...advertiser,
                          refresh: value || 0,
                        })
                      }
                    />
                  </Form.Item>
                </>
              )}

              {advertiser.adType === "firstImpression" && (
                <Form.Item
                  label="Choose if Skip"
                  // name="skip"

                  rules={[{ required: true, message: "Please select if Skip" }]}
                  style={{ width: "31%" }}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    // disabled={ifSetupsDisabled()}
                    placeholder="Please select if Skip"
                    options={[
                      { value: 0, label: "No" },
                      { value: 1, label: "Yes" },
                    ].map((item: any) => {
                      return { value: item.value, label: item.label };
                    })}
                    value={advertiser.skip ? "Yes" : "No"}
                    onChange={(value) =>
                      setAdvertiser({
                        ...advertiser,
                        skip: value,
                      })
                    }
                  />
                </Form.Item>
              )}
              {advertiser.adType && advertiser.adType.includes("inContent") && (
                <Form.Item
                  label="Choose Location"
                  // name="location"
                  rules={[
                    { required: true, message: "Please select Location" },
                  ]}
                  style={{ width: "25%" }}
                >
                  <Select
                    value={advertiser.location || []}
                    allowClear
                    mode="multiple"
                    placeholder="Please select Location"
                    options={Array.from(
                      { length: 20 },
                      (_, index) => index + 1
                    ).map((value: number) => {
                      return { value: value, label: value };
                    })}
                    onChange={(value) => {
                      let all = Array.from(
                        { length: 20 },
                        (_, index) => index + 1
                      ).map((value: number) => {
                        return value;
                      });

                      setAdvertiser({
                        ...advertiser,
                        location: value.length > 0 ? value : all,
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Row>
          </>
        )}

        <Row style={{ justifyContent: "center", gap: "16px" }}>
          <Button type="default" title="Cancel" onClick={close}></Button>
          <Button
            id={IDS.NEW_TAG.ADD_BUTTON}
            type="primary"
            title={"Add Tag"}
            htmlType="submit"
            loading={loading}
          ></Button>
        </Row>
      </Form>
      {contextHolder}
    </Modal>
  );
};

export default AdvertisersModal;
