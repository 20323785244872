import { User } from "@auth0/auth0-react";
import { configureStore } from "@reduxjs/toolkit";

export interface AppState {
  publisher: { key: number; public_key: string; pub_name: string };
  isDarkMode: boolean;
  roles: string[];
  user: User;
  permissions: any[] | undefined;
  showLogOutModal: boolean;
  permissionModalData: { show: boolean; message: string };
  domain: string | undefined;
}

const initialState: AppState = {
  publisher: JSON.parse(localStorage.getItem("domain") || "{}"),
  isDarkMode: false,
  roles: [],
  user: {},
  permissions: undefined,
  showLogOutModal: false,
  permissionModalData: {} as any,
  domain: undefined,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_PUBLISHER": {
      localStorage.setItem("domain", JSON.stringify(action.payload));
      return { ...state, publisher: action.payload };
    }
    case "SET_THEME":
      return { ...state, isDarkMode: action.payload };

    case "SET_ROLES":
      return { ...state, roles: action.payload };

    case "SET_USER":
      return { ...state, user: action.payload };

    case "SET_PERMISSIONS":
      return { ...state, permissions: action.payload };

    case "SET_DOMAIN":
      return { ...state, domain: action.payload };

    case "SET_SHOW_LOGOUT_MODAL":
      return { ...state, showLogOutModal: action.payload };

    case "SET_SHOW_NO_PERMISSION_MODAL":
      return { ...state, permissionModalData: action.payload };

    default:
      return state;
  }
};

const store = configureStore({
  reducer,
});

export default store;
