export const IDS = {
  DASHBOARD: {
    ADS_TXT_BUTTON: "dashboard_ads_txt_button",
    EXPORT_PDF: "dashboard_export_pdf",
    EXPORT_EXCEL: "dashboard_export_excel",
    PUBLISHER_SELECT: "dashboard_publisher_select",
    DOMAIN_SELECT: "dashboard_domain_select",
    RANGE_SELECT: "dashboard_range_select",
    DATES_RANGE_SELECT: "dashboard_range_select",
    DOMAINS_SELECT: "dashboard_domains_select",
    PLATFORM_SELECT: "dashboard_platform_select",
  },
  EXPLORE: {
    PUBLISHER_SELECT: "explore_publisher_select",
    DOMAIN_SELECT: "explore_domain_select",
    HASHTAG: "explore_hashtag",
    PLATFORM_SELECT: "explore_platform_select",
    SHOW_BUTTON: "explore_show_button",
    SAVE_BUTTON: "explore_save_button",
    INSTAGRAM_CONTAINER: "explore_instagram_container",
    TIKTOK_CONTAINER: "explore_tiktok_container",
    TWITTER_CONTAINER: "explore_twitter_container",
    INSTAGRAM_FILTER_BUTTON: "explore_instagram_filter_button",
    TIKTOK_FILTER_BUTTON: "explore_tiktok_filter_button",
    TWITTER_FILTER_BUTTON: "explore_twitter_filter_button",
  },
  MANAGMENT: {
    PUBLISHER_SELECT: "managment_publisher_select",
    DOMAIN_SELECT: "managment_domain_select",
    SHOW_BUTTON: "managment_show_button",
    REMOVE_BUTTON: "managment_remove_button",
    INSTAGRAM_CONTAINER: "managment_instagram_container",
    TIKTOK_CONTAINER: "managment_tiktok_container",
    TWITTER_CONTAINER: "managment_twitter_container",
  },
  UNIT_PREVIEW: {
    LAYOUT_BUTTON: "unit_preview_layout_button",
    MODE: "unit_preview_mode",
    PREVIEW_BUTTON: "unit_preview_preview_button",
  },
  ADVERTISERS: {
    PUBLISHER_SELECT: "advertisers_publisher_select",
    DOMAIN_SELECT: "advertisers_domain_select",
    TAGTYPE_SELECT: "advertisers_tagtype_select",
    WID_INPUT: "advertisers_wid_input",
    ID_INPUT: "advertisers_id_input",
    SETUP_BUTTON: "advertisers_setup_button",
    NEW_BUTTON: "advertisers_new_button",
    CONFIGS_SAVE_BUTTON: "configs_save_configs_button",
    CONFIGS_PUBLISHER_SELECT: "configs_publisher_select",
    CONFIGS_DOMAIN_SELECT: "configs_domain_select",
    CONFIGS_WID_INPUT: "configs_wid_input",
    CONFIGS_TIMER_INPUT: "configs_timer_input",
  },

  NEW_TAG: {
    PUBLISHER_SELECT: "new_tag_publisher_select",
    DOMAIN_SELECT: "new_tag_domain_select",
    TAGTYPE_SELECT: "new_tag_tagtype_select",
    PLATFORM_SELECT: "new_tag_platform_select",
    WID_INPUT: "new_tag_wid_input",
    ID_INPUT: "new_tag_id_input",
    SETUP_BUTTON: "new_tag_setup_button",
    ADD_BUTTON: "new_tag_add_button",
    ADTYPE_SELECT: "new_tag_adtype_select",
    TIMER_INPUT: "new_tag_timer_input",
    BIDFLOOR_INPUT: "new_tag_bidfloor_input",
    TIMEOUT_INPUT: "new_tag_timeout_input",
  },

  EXTENSION_UPDATE: {
    IMPLEMENTATION_SELECT: "extension_update_implementation_select",
    UNITTYPE_SELECT: "extension_update_unittype_select",
    CATEGORYTYPE_SELECT: "extension_update_categorytype_select",
    PREVIEW_BUTTON: "extension_update_preview_button",
    SAVE_BUTTON: "extension_update_save_button",
  },

  PUBLISHERS: {
    PUBLISHER_SEARCH: "publisher_select",
  },
  PROVIDERS: {
    ADD_BUTTON: "new_provider_add_button",
  },
};
