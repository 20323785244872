import React from "react";
import { Modal as ModalAntD } from "antd";
interface ModalProps {
  children: React.ReactNode;
  title: React.ReactNode;
  isModalOpen: boolean;
  setIsModalOpen: (flag: boolean) => void;
  width?: number | string;
  height?: number | string;
  hideOkButton?: boolean;
  hideCncelButton?: boolean;
  okText?: React.ReactNode;
  okDisabled?: boolean;
  onOk?: () => void;
  className?: string;
  removeCloseButton?: boolean;
}

const Modal = (props: ModalProps) => {
  const {
    children,
    title,
    isModalOpen,
    setIsModalOpen,
    width,
    hideOkButton,
    hideCncelButton,
    okText,
    okDisabled,
    onOk,
    className,
    removeCloseButton,
  } = props;
  const handleOk = () => {
    onOk && onOk();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ModalAntD
        width={width ? width : "40%"}
        style={{ cursor: "move" }}
        title={title}
        open={isModalOpen}
        onOk={handleOk}
        okText={okText}
        onCancel={handleCancel}
        {...(hideCncelButton && {
          cancelButtonProps: { style: { display: "none" } },
        })}
        {...(hideOkButton && {
          okButtonProps: { style: { display: "none" } },
        })}
        {...(okDisabled && {
          okButtonProps: { disabled: true },
        })}
        {...(className && { className: className })}
        {...(removeCloseButton && { closable: false })}
        styles={{ body: {
          overflowY: "auto",
          maxHeight: "100vh",
          marginBottom: "auto",
          marginTop: "auto",
        } }}
      >
        {children}
      </ModalAntD>
    </>
  );
};

export default Modal;
