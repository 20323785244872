import { useEffect, useMemo, useState } from "react";
import { Col, ColorPicker, Space } from "antd";
import type { ColorPickerProps } from "antd/es/color-picker";
import { Form, Row } from "antd";

const ColorPickerUnit = (props: any) => {
  const { keyColor, colorRgb, setColorRgb } = props;

  const [formatRgb, setFormatRgb] = useState<ColorPickerProps["format"]>("rgb");
  const rgbString = useMemo(
    () => (typeof colorRgb === "string" ? colorRgb : colorRgb.toRgbString()),
    [colorRgb]
  );

  useEffect(() => {
    setColorRgb(rgbString);
  }, [colorRgb, rgbString, setColorRgb]);

  return (
    <Form.Item
      label={`${keyColor}`}
      name={`key_${keyColor}`}
      style={{ width: "26%" }}
    >
      <Row align="middle">
        <Space>
          <Col>
            <ColorPicker
              format={formatRgb}
              value={colorRgb}
              onChange={setColorRgb}
              onFormatChange={setFormatRgb}
            />
          </Col>
          <Col>
            <span style={{ fontWeight: 200 }}>{rgbString}</span>
          </Col>
        </Space>
      </Row>
    </Form.Item>
  );
};

export default ColorPickerUnit;
