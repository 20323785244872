import { useEffect, useRef, useState } from "react";
import { Col, Row, Image, Tooltip } from "antd";
import Card from "../../components/Card/Card";
import IntuitiveCard from "../../components/Card/IntuitiveCard";
import Select from "../../components/Select/Select";
import pdfIcon from "../../assets/images/pdfIcon.svg";
import note_dark from "../../assets/images/note-dark.svg";
import note_light from "../../assets/images/note-light.svg";
import excelIcon from "../../assets/images/excelIcon.svg";
import Button from "../../components/Button";
import { useSelector } from "react-redux";
import PerformanceChart from "./PerformanceChart";
import PureCard from "../../components/PureCard/PureCard";
import { AppState } from "../../store/store";
import Modal from "../../components/Modal/Modal";
import ads_Txt from "../../data/ads_Txt";
import { DownloadOutlined, CopyOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { getDomainsByPubId } from "../../api/services/Domains";
import { getDashboardData } from "../../api/services/Dashboard";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "./styles.scss";
import IntervalTable from "./IntervalTable";
import {
  checkPermissionOk,
  getCardValue,
  getReportData,
} from "../../utils/utils";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import GdprModal from "../../modals/GdprModal";
import { RangePickerProps } from "antd/es/date-picker";
import PublisherSelect from "../DomainSelection/PublisherSelect";
import { sendGtmEvent } from "../../gtm";
import { IDS } from "../../utils/elementsIds";
import Metrics from "./Metrics";

const { RangePicker } = DatePicker;
dayjs.extend(utc);

const Dashboard = () => {
  const publisher = useSelector((state: AppState) => state.publisher);
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);
  const [isModalTxtOpen, setIsModalTxtOpen] = useState(false);
  const [isModalGdprOpen, setIsModalGdprOpen] = useState(false);
  const [domainIds, setDomainIds] = useState<any[]>([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(29, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [platformValue] = useState("All");
  const [isLoading, setIsLoading] = useState(true);
  const [domainSelected, setDomainSelected] = useState<any[] | undefined>(
    undefined
  );
  const roles = useSelector((state: AppState) => state.roles);
  const [dashboardData, setDashboardData] = useState<any>(undefined as any);

  const { user } = useAuth0();
  const dateFormat = "MM/DD/YYYY";
  const screenshotRef = useRef(null);
  const permissions = useSelector((state: AppState) => state.permissions); 

  useEffect(() => {
    getDashboard();
  }, [user, publisher, domainSelected, startDate, endDate]);

  const getPubId = () => {
    if (user) {
      if (
        !roles.includes("admin") &&
        !roles.includes("sales") &&
        !roles.includes("operation")
      ) {
        let { pub_id } =
          user["dev-ovcxuhr3lpxgrden.us.auth0.com/user_metadata"];
        return pub_id;
      } else {
        return publisher?.key;
      }
    } else return 0;
  };

  const getDashboard = async (start?: dayjs.Dayjs, end?: dayjs.Dayjs) => {
    setIsLoading(true);
    let pub_id = getPubId();
    let domainIds = await getDomainsByPubId(pub_id);
    if(domainSelected && domainSelected.length > 0 && !domainIds.find((item: any) => item.domain_id === domainSelected[0].domain_id)){
      setDomainSelected(undefined);
    }
    
    let s =
      (start && start.startOf("day").format("YYYY-MM-DD")) ||
      startDate.startOf("day").format("YYYY-MM-DD");
    let e =
      (end && end.endOf("day").format("YYYY-MM-DD")) ||
      endDate.endOf("day").format("YYYY-MM-DD");
    let response = await getDashboardData(
      pub_id,
      s,
      e,
      0,
      getDomainsToSend(domainIds),
      publisher?.public_key
    );
    setDomainIds(domainIds);
    setDashboardData(response);
    setIsModalGdprOpen(response.PublisherData.ShowGDPRCompianceMessage);
    setIsLoading(false);
  };

  const handleDateChange = async (dates: any) => {
    setStartDate(dayjs(new Date(dates[0].$d).toISOString()));
    setEndDate(dayjs(new Date(dates[1].$d).toISOString()));
    setIsLoading(true);
    let start = dayjs(new Date(dates[0].$d).toISOString());
    let end = dayjs(new Date(dates[1].$d).toISOString());
    await getDashboard(start, end);
  };
  const getDomainsToSend = (domainIds: any[]) => {
    if (domainSelected && domainSelected.length > 0) {
      return domainSelected.map((item: any) => item.domain_id);
    } else return domainIds.map((item: any) => item.domain_id);
  };

  const copyAdsText = () => {
    var copyText =
      "carambo.la, " +
      publisher?.public_key +
      ", DIRECT, 08a6b436f8183ac9\n" +
      ads_Txt;
    navigator.clipboard.writeText(copyText);
  };

  const downloadAdsText = () => {
    let fileName = "Carambola ads.txt";
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(
          "carambo.la, " +
            publisher?.public_key +
            ", DIRECT, 08a6b436f8183ac9\n" +
            ads_Txt
        )
    );
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const getDomains = () => {
    let a = [{ label: "All Domains", value: "0" }];
    domainIds.map((domain: any) => {
      a.push({ label: domain.name, value: domain.domain_id });
      return domain;
    });
    return a;
  };

  const getLastXOptions = () => {
    let a: any[] = [];
    let options = [
      "Last 30 Days",
      "Last Day",
      "Last 7 Days",
      "Month to Date",
      "Last Month",
    ];

    options.map((option: any, index: number) => {
      a.push({ label: option, value: option });
      return option;
    });
    return a;
  };
  const getCardSpan = (index: number) => {
    if (dashboardData?.Fields.length) {
      let length = dashboardData.Fields.length;
      switch (length) {
        case 3:
          return 8;
        case 4:
          return 6;
        case 5:
          return index <= 2 ? 8 : 12;
        case 6:
          return 8;
        case 7:
          return index <= 2 ? 8 : 6;
        case 8:
          return 6;
        default:
          return 8;
      }
    }
  };

  const setDatesRange = (value: string) => {
    if (value === "Last 30 Days") {
      setEndDate(dayjs());
      setStartDate(dayjs().subtract(29, "day"));
    }
    if (value === "Last Day") {
      setEndDate(dayjs());
      setStartDate(dayjs());
    }
    if (value === "Last 7 Days") {
      setEndDate(dayjs());
      setStartDate(dayjs().subtract(6, "day"));
    }
    if (value === "Month to Date") {
      setEndDate(dayjs());
      setStartDate(dayjs().startOf("month"));
    }
    if (value === "Last Month") {
      setEndDate(dayjs().subtract(1, "month").endOf("month"));
      setStartDate(
        dayjs().subtract(1, "month").startOf("month").startOf("day")
      );
    }
  };
  const getLoadingBoard = () => {
    return (
      <Row gutter={16}>
        {[0, 1, 2, 3, 4, 5].map((item, index) => (
          <Col span={8} key={index}>
            <Card isLoading={true} />
          </Col>
        ))}
      </Row>
    );
  };
  const exportXlsx = () => {
    const worksheet = XLSX.utils.json_to_sheet(getReportData(dashboardData));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    let fileName = `Carambola data ${platformValue} ${dayjs(startDate).format(
      "MM_DD_YYYY"
    )} - ${dayjs(endDate).format("MM_DD_YYYY")}.xlsx`;
    XLSX.writeFile(workbook, fileName);
    sendGtmEvent("Excell report", user);
  };

  const exportPdf = async () => {
    let fileName = `Carambola data ${dayjs(startDate).format(
      "MM_DD_YYYY"
    )} - ${dayjs(endDate).format("MM_DD_YYYY")}.pdf`;

    const element: any = screenshotRef.current;
    const canvas = await html2canvas(element);
    const pdf = new jsPDF();
    pdf.addImage(
      canvas.toDataURL("image/png"),
      "PNG",
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      pdf.internal.pageSize.getHeight()
    );
    pdf.save(fileName);
    sendGtmEvent("PDF report", user);
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const getModals = () => {
    return (
      <>
        <Modal
          title={
            <Row gutter={12}>
              {/* Copy the following text to your ads.txt file: */}
              <Col>
                <Button
                  title="Download"
                  type="primary"
                  size="middle"
                  onClick={downloadAdsText}
                  icon={<DownloadOutlined />}
                ></Button>
              </Col>
              <Col>
                <Button
                  title="Copy"
                  type="primary"
                  size="middle"
                  onClick={copyAdsText}
                  icon={<CopyOutlined />}
                ></Button>
              </Col>
            </Row>
          }
          isModalOpen={isModalTxtOpen}
          setIsModalOpen={setIsModalTxtOpen}
        >
          <Col style={{ padding: 8 }}>
            {"carambo.la, " +
              publisher?.public_key +
              ", DIRECT, 08a6b436f8183ac9\n"}
            {ads_Txt.split("\n").map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </Col>
        </Modal>

        <GdprModal
          onClose={() => setIsModalGdprOpen(false)}
          isModalOpen={isModalGdprOpen}
          pub_id={getPubId()}
        />
      </>
    );
  };

  const getFilterWidth = () => {
    return roles.includes("admin") ||
      roles.includes("sales") ||
      roles.includes("operation")
      ? "16%"
      : "20%";
  };

  return (
    <div ref={screenshotRef}>
      <>
        <Row justify={"space-between"} align={"middle"} className="content-row">
          <Col span={6} style={{ color: isDarkMode ? "#FDB813" : "#4097FF" }}>
            {domainIds.length > 0 && <h1>{domainIds[0].pub_name}</h1>}
          </Col>
        </Row>
        <Row justify="space-between" gutter={30}>
          {(roles.includes("admin") ||
            roles.includes("sales") ||
            roles.includes("operation")) && (
            <Col style={{ width: getFilterWidth() }}>
              <PublisherSelect
                disabled={isLoading}
                id={IDS.DASHBOARD.PUBLISHER_SELECT}
              />
            </Col>
          )}

          <Col style={{ width: getFilterWidth() }}>
            <RangePicker
              id={IDS.DASHBOARD.DATES_RANGE_SELECT}
              disabled={isLoading}
              style={{
                height: "40px",
                borderRadius: "8px",
                margin: 0,
              }}
              defaultValue={[startDate, endDate]}
              onChange={handleDateChange}
              format={dateFormat}
              value={[startDate, endDate]}
              disabledDate={disabledDate}
              allowClear={false}
            />
          </Col>

          <Col style={{ width: getFilterWidth() }}>
            <Select
              id={IDS.DASHBOARD.RANGE_SELECT}
              disabled={isLoading}
              defaultValue={"Last 30 Days"}
              options={getLastXOptions()}
              onChange={(value) => {
                setDatesRange(value);
              }}
            />
          </Col>
          <Col style={{ width: getFilterWidth() }}>
            <Select
              disabled
              defaultValue="By Day"
              options={[
                { label: "By Day", value: "By Day" },
                { label: "By Week", value: "By Week" },
              ]}
            />
          </Col>
          <Col style={{ width: getFilterWidth() }}>
            <Select
              key={getPubId()}
              id={IDS.DASHBOARD.DOMAINS_SELECT}
              disabled={isLoading}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              defaultValue="0"
              options={getDomains()}
              onChange={async (value, option) => {
                if (value === "0") {
                  setDomainSelected(domainIds);
                } else {
                  setDomainSelected([
                    domainIds.find(
                      (element) => element.domain_id === option.value
                    ),
                  ]);
                }
                // await getDashboard();
              }}
            />
          </Col>
          <Col style={{ width: getFilterWidth() }}>
            <Tooltip placement="topLeft" title={"Export Pdf"}>
              <span>
                <Image src={pdfIcon} preview={false} onClick={exportPdf} className="cursor-pointer" />
              </span>
            </Tooltip>

            <Tooltip
              placement="topLeft"
              title={"Export Excel"}
              id={IDS.DASHBOARD.EXPORT_EXCEL}
            >
              <span>
                <Image src={excelIcon} preview={false} onClick={exportXlsx} className="cursor-pointer" />
              </span>
            </Tooltip>
            {/* <Select
              id={IDS.DASHBOARD.PLATFORM_SELECT}
              disabled={isLoading}
              options={[
                { label: "All Platforms", value: "All" },
                { label: "Desktop", value: "Desktop" },
                { label: "Mobile", value: "Mobile" },
              ]}
              onChange={(value) => {
                setPlatformValue(value);
              }}
              defaultValue={"All"}
            /> */}
          </Col>
        </Row>
        {!isLoading &&
          permissions &&
          !checkPermissionOk("read:metrics", permissions) && (
            <Row gutter={16}>
              {dashboardData?.Fields?.map(
                (filed: any, index: number) =>
                  (dashboardData?.MetricsSelection.includes(
                    filed.keyForSelection
                  ) ||
                    dashboardData?.MetricsSelection.length === 0) && (
                    <Col key={index} span={getCardSpan(index)}>
                      <IntuitiveCard
                        title={filed.Label}
                        description={getCardValue(filed, platformValue)}
                        desktop={filed.Desktop}
                        mobile={filed.Mobile}
                      />
                    </Col>
                  )
              )}
            </Row>
          )}

        {permissions && checkPermissionOk("read:metrics", permissions) && (
          <Row gutter={0} style={{ background: "", width: "100%" }}>
            <Metrics
              isLoading={isLoading}
              platformValue={platformValue}
              fields={dashboardData?.Fields}
              metricsSelection={dashboardData?.MetricsSelection}
              pub_id={getPubId()}
              setIsLoading={(flag: boolean) => setIsLoading(flag)}
            />
          </Row>
        )}
        {isLoading &&
          permissions &&
          !checkPermissionOk("read:metrics", permissions) &&
          getLoadingBoard()}
        <Row>
          <Col span={24}>
            <PureCard>
              <PerformanceChart
                platform={platformValue}
                isLoading={isLoading}
                data={dashboardData}
              />
            </PureCard>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <PureCard>
              {<IntervalTable isLoading={isLoading} data={dashboardData} />}
            </PureCard>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ marginBottom: 50 }}>
            <PureCard>
              <Row>
                <Image
                  src={isDarkMode ? note_dark : note_light}
                  preview={false}
                />
                <h2 style={{ marginLeft: 16 }}>
                  Please note that numbers will be final for invoicing on the
                  5th of each month.
                </h2>
              </Row>
            </PureCard>
          </Col>
        </Row>
      </>
      {getModals()}
    </div>
  );
};

export default Dashboard;
