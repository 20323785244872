import client from "../client";
import { User } from "@auth0/auth0-react";
import { Domain } from "../../utils/types";

export const getDomains = async () => {
  const res = await client.get("/domains");
  return res.data;
};

export const getActiveDomainsUrls = async () => {
  const res = await client.get("/domains/urls");
  return res.data;
};

export const searchSingleLines = async (
  domain_urls: string[],
  line: string
) => {
  const res = await client.post(`domains/txtfiles`, {
    domain_urls: domain_urls,
    line: line,
  });
  return res.data;
};

export const searchMultiLines = async (domain_url: string, lines: string[]) => {
  const res = await client.post(`domains/multi_lines`, {
    domain_url: domain_url,
    lines: lines,
  });
  return res.data;
};

export const getEmbeds = async (urls: string[]) => {
  const res = await client.post(`domains/embed_scanner`, {
    urls: urls,
  });
  return res.data;
};

export const deleteActiveDomain = async (domain_id: any) => {
  const res = await client.delete(`/domains/active_domain`, {
    data: { domain_id: domain_id },
  });
  return res.data;
};

export const getDomainsByPubId = async (pub_id: number) => {
  const res = await client.get(`domains/${pub_id}`);
  return res.data;
};

export const getDomainWID = async (domain_id: number) => {
  const res = await client.get(`domains/wid/${domain_id}`);
  return res.data;
};

export const getDomainSettings = async (domain_id: number) => {
  const res = await client.get(`domains/settings/${domain_id}`);
  return res.data;
};

export const insertDomain = async (
  domain: Domain,
  user: User,
  pub_key:string,
  InContentThemeColor: string,
  InContentThemeHoverColor: string,
  InContentTitleBGColor: string,
  defaultAiCategory: string | null,
  defaultRevenueSource: string | null,
  widgets?:any,
) => {
  const res = await client.post(`/domains/insert`, {
    domain: domain,
    user: user,
    pub_key:pub_key,
    InContentThemeColor: InContentThemeColor,
    InContentThemeHoverColor: InContentThemeHoverColor,
    InContentTitleBGColor: InContentTitleBGColor,
    defaultAiCategory: defaultAiCategory || '',
    widgets:widgets,
    defaultRevenueSource: defaultRevenueSource
  });
  return res;
};

export const updateDomain = async (
  domain: Domain,
  user: User,
  InContentThemeColor: string,
  InContentThemeHoverColor: string,
  InContentTitleBGColor: string,
  defaultAiCategory: string | null,
  defaultRevenueSource: string | null
) => {
 const res = await client.post(`/domains/update`, {
   domain: domain,
   user: user,
   InContentThemeColor: InContentThemeColor,
   InContentThemeHoverColor: InContentThemeHoverColor,
   InContentTitleBGColor: InContentTitleBGColor,
   defaultAiCategory: defaultAiCategory,
   defaultRevenueSource: defaultRevenueSource
 });
 return res;
};
