import { useEffect, useState } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";
import Select from "../../components/Select/Select";
import { AppState } from "../../store/store";
import { checkPermissionOk, getallDomainesUrl } from "../../utils/utils";
import { useAuth0 } from "@auth0/auth0-react";
import { Mode } from "../../utils/types";

interface DomainSelectProps {
  onSelect?: (value: any) => void;
  allowClear?: boolean;
  disabled?: boolean;
  value?: any;
  mode?: Mode;
  id?: string;
  defaultValue?: string;
  pub_id?: number;
}

const DomainSelect = (props: DomainSelectProps) => {
  const { allowClear, disabled, value, id, defaultValue, pub_id } = props;
  const [options, setOptions] = useState<any[]>([]);
  const publisher = useSelector((state: AppState) => state.publisher);
  const { user } = useAuth0();
  const permissions = useSelector((state: AppState) => state.permissions);
  const dispatch = useDispatch();

  useEffect(() => {
    let getDomainsList = async () => {
      let data = await getallDomainesUrl(pub_id || publisher.key || getPubId());
      setOptions(data);
    };
    getDomainsList();
  }, [publisher, pub_id]);

  const getPubId = () => {
    if (user) {
      if (!(permissions && checkPermissionOk("read:domains", permissions))) {
        let { pub_id } =
          user["dev-ovcxuhr3lpxgrden.us.auth0.com/user_metadata"];
        return pub_id;
      } else {
        return publisher?.key;
      }
    } else return 0;
  };

  const onSelect = (value: any) => {
    dispatch({ type: "SET_DOMAIN", payload: value });
    props.onSelect && props.onSelect(value);
  };

  const onClear = () => {
    props.onSelect && props.onSelect("");
  };

  return (
    <div style={{ width: "100%" }}>
      {options.length === 0 && (
        <Skeleton.Input active style={{ width: "100%" }} size="large" />
      )}

      {options.length > 0 && (
        <Select
          value={value ? value : undefined}
          onClear={onClear}
          allowClear={allowClear}
          placeholder="Select domain"
          onSelect={onSelect}
          options={options}
          disabled={disabled}
          filterOption={(input: any, option: any) =>
            option.label?.toLowerCase().includes(input.toLowerCase())
          }
          showSearch
          {...(id && { id })}
          {...(defaultValue && { defaultValue })}
        />
      )}
    </div>
  );
};

export default DomainSelect;
