import React from "react";
import { Card as CardAntD, Skeleton } from "antd";
import "./styles.scss";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
const { Meta } = CardAntD;

interface CardProps {
  title?: string;
  description?: any;
  onEmptySearch?: () => void;
  actions?: React.ReactNode[];
  isLoading?: boolean;
  fontSize?: "small" | "medium" | "large";
}

const Card = (props: CardProps) => {
  const { title, description, actions, isLoading, fontSize } = props;
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  return (
    <CardAntD
      className="card"
      title={
        <h4
          style={{
            color: isDarkMode ? "#FDB813" : "#4097FF",
            fontSize: "13px",
            whiteSpace: "initial",
          }}
        >
          {title}
        </h4>
      }
      actions={actions || undefined}
    >
      {isLoading ? (
        <Skeleton loading={true} avatar active></Skeleton>
      ) : (
        <Meta
          className="meta"
          description={
            fontSize !== "small" ? (
              <h1 style={{ color: isDarkMode ? "white" : "#1E1E1E" }}>
                {description}
              </h1>
            ) : (
              <div style={{ color: isDarkMode ? "white" : "#1E1E1E" }}>
                {description}
              </div>
            )
          }
        />
      )}
    </CardAntD>
  );
};
export default Card;
